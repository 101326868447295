const infoBoxTexts = {
  users:
    'This table lists all users in your company. You can add new users, assign them to user groups, resend invitations, or delete them.',
  policies:
    'This table lists all policies in your company. You can create, edit, duplicate, or delete them. Policies can be assigned to user groups in order to give specific access to a group of users.',
  userGroups:
    'This table lists all user groups in your company. User groups are used to manage permissions regarding actions, sections of the application, and aws accounts for multiple users at once. You can create, edit, duplicate, or delete them. In order to assign permissions to users, add a policy and users to a user group. ',
  awsAccounts:
    'Connect AWS accounts to send emails through AWS SES. Check the account info, sending statistics, validate permissions, and add, edit or remove accounts.',
  tags:
    'This table lists all tags in your company. Tags are used to organize, and filter your email templates. You can create, edit, or delete them.',
};

export default infoBoxTexts;
