import React from 'react';
import { TagFilterIconButton } from 'components/Menu/Templates/Shared';
import { StatusFilterIconButton } from 'components/Menu/Templates/Shared';

const templateColumns = [
  // {
  //   id: 'html',
  //   label: 'Template',
  //   type: 'html',
  //   sortable: false,
  //   width: '10%',
  // },
  {
    id: 'title',
    label: 'Template',
    type: 'title',
    sortable: true,
    width: '30%',
  },
  {
    id: 'updatedTimestamp',
    label: 'Updated',
    type: 'date',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    type: 'status',
    filterComponent: <StatusFilterIconButton />,
  },
  {
    id: 'tags',
    label: 'Tags',
    type: 'tags',
    width: '25%',
    filterComponent: <TagFilterIconButton />,
  },
  {
    id: 'create',
    label: 'Actions',
    type: 'actions',
    align: 'right',
  },
];

export default templateColumns;
