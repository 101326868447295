import React, { useContext, useEffect, useState } from 'react';
import { useAsyncAction, useLocalStorage } from 'state/hooks';
import { getTemplates } from 'state/actions';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useDispatch } from 'react-redux';
import { LogoLoader } from 'components/Base';
import { arraysAreEqual } from '../utils';
import PropTypes from 'prop-types';
import { DEFAULT_STATUS_FILTERS } from 'consts/defaults';

const TemplatesContext = React.createContext(null);

export const useTemplates = () => useContext(TemplatesContext);

const TemplatesProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [
    localStorageTemplateRows,
    setLocalStorageTemplateRows,
  ] = useLocalStorage('templateRowsPerPage', 10);

  const [query, setQuery] = useQueryParams({
    order: withDefault(StringParam, 'desc'),
    orderBy: withDefault(StringParam, 'updated_timestamp'),
    filter: withDefault(StringParam, ''),
    filteredTags: withDefault(ArrayParam, []),
    statusFilter: withDefault(ArrayParam, DEFAULT_STATUS_FILTERS),
    pageNumber: withDefault(NumberParam, 0),
  });

  const {
    filter,
    filteredTags,
    statusFilter,
    orderBy,
    order,
    pageNumber,
  } = query;

  const [tagFilterCount, setTagFilterCount] = useState(filteredTags.length);
  const [statusFilterCount, setStatusFilterCount] = useState(
    statusFilter.length
  );
  const [statusFilterState, setStatusFilterState] = useState(statusFilter);
  const [pageNumberState, setPageNumberState] = useState(pageNumber);
  const [filterState, setFilterState] = useState(filter);
  const [filteredTagsState, setFilteredTagsState] = useState(filteredTags);
  const [orderByState, setOrderByState] = useState(orderBy);
  const [orderState, setOrderState] = useState(order);
  const [templatesPerPageState, setTemplatesPerPageState] = useState(
    localStorageTemplateRows
  );

  const updateQueryParams = (update) => {
    setQuery(update);
  };

  const { data: templates, loading: templatesLoading } = useAsyncAction({
    action: getTemplates,
    values: {
      limit: localStorageTemplateRows,
      filter,
      skip: pageNumber * localStorageTemplateRows,
      tagIds: filteredTags,
      orderBy,
      order,
      status: statusFilter,
    },
  });

  useEffect(() => {
    const skip = pageNumber * localStorageTemplateRows;

    if (
      filter !== filterState ||
      !arraysAreEqual(filteredTags, filteredTagsState) ||
      pageNumber !== pageNumberState ||
      orderBy !== orderByState ||
      order !== orderState ||
      localStorageTemplateRows !== templatesPerPageState ||
      !arraysAreEqual(statusFilter, statusFilterState)
    ) {
      dispatch(
        getTemplates({
          limit: localStorageTemplateRows,
          filter,
          skip,
          tagIds: filteredTags,
          orderBy,
          order,
          status: statusFilter,
        })
      );
      setTagFilterCount(filteredTags.length);
      setPageNumberState(pageNumber);
      setFilteredTagsState(filteredTags);
      setFilterState(filter);
      setStatusFilterState(statusFilter);
      setStatusFilterCount(statusFilter.length);
      setOrderState(order);
      setOrderByState(orderBy);
      setTemplatesPerPageState(localStorageTemplateRows);
    }
  }, [
    dispatch,
    templates,
    templatesLoading,
    filter,
    filterState,
    filteredTags,
    filteredTagsState,
    statusFilter,
    statusFilterState,
    pageNumber,
    pageNumberState,
    order,
    orderState,
    orderBy,
    orderByState,
    templatesPerPageState,
    localStorageTemplateRows,
  ]);

  if (templatesLoading) return <LogoLoader />;

  return (
    <TemplatesContext.Provider
      value={{
        updateQueryParams,
        filteredTags,
        tagFilterCount,
        statusFilterCount,
        statusFilterState,
        filter,
        orderBy,
        order,
        pageNumber,
        rowsPerPage: localStorageTemplateRows,
        setRowsPerPage: setLocalStorageTemplateRows,
        templates,
        setLocalStorageTemplateRows,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};

TemplatesProvider.propTypes = {
  children: PropTypes.any,
};

export default TemplatesProvider;
