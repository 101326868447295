const INITIAL_STATE = {
  templates: undefined,
  count: 0,
  samples: undefined,
  importableTemplates: [],
  selectedTemplate: undefined,
};

const applySetTemplates = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const applySetStarters = (state, { payload }) => {
  return {
    ...state,
    samples: payload,
  };
};

const applySetImportableTemplates = (state, { payload }) => {
  return {
    ...state,
    importableTemplates: payload,
  };
};

const applyUpdateImportableTemplates = (state, { payload }) => {
  const importableTemplates = state.importableTemplates.filter(
    (template) => !payload.includes(template.name)
  );
  return {
    ...state,
    importableTemplates,
  };
};

const applyUpdateSelectedTemplate = (state, { payload }) => {
  const templates = state.templates;

  if (state.templates) {
    const updatedTemplateIndex = state?.templates?.findIndex(
      (template) => template.uid === payload.uid
    );
    templates[updatedTemplateIndex] = payload;
  }
  return {
    ...state,
    templates,
    selectedTemplate: {
      ...state.selectedTemplate,
      ...payload,
    },
  };
};

const applyUpdateSelectedTemplateVersions = (state, { payload }) => {
  return {
    ...state,
    selectedTemplateVersions: payload,
  };
};

const applyDeleteTemplate = (state, { payload }) => {
  const templates = state.templates?.filter((item) => item.uid !== payload);

  const count = state.count - 1;
  return {
    ...state,
    templates,
    count,
  };
};

const templateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TEMPLATE_LIST_SET': {
      return applySetTemplates(state, action);
    }
    case 'TEMPLATE_SELECTED_SET': {
      return applyUpdateSelectedTemplate(state, action);
    }
    case 'TEMPLATE_VERSIONS_SELECTED_SET': {
      return applyUpdateSelectedTemplateVersions(state, action);
    }
    case 'TEMPLATE_DELETE': {
      return applyDeleteTemplate(state, action);
    }
    case 'TEMPLATE_SAMPLES_SET': {
      return applySetStarters(state, action);
    }
    case 'IMPORTABLE_TEMPLATES_SET': {
      return applySetImportableTemplates(state, action);
    }
    case 'IMPORTABLE_TEMPLATES_UPDATE': {
      return applyUpdateImportableTemplates(state, action);
    }
    default:
      return state;
  }
};

export default templateReducer;
