import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  TemplateVersions,
  TemplateEditor,
  TemplatePreview,
  TemplatesList,
  TemplateStarters,
} from 'components/Menu/Templates';
import { Layout } from 'components/Structure';
import {
  templateVersions,
  templateEditor,
  templatePreview,
  templates,
  templateStarters,
} from 'consts/routes';
import { PlaceholderProvider, TemplatesProvider } from 'components/Contexts';

const Templates = () => {
  const title = 'Templates';

  return (
    <Switch>
      <Route
        exact
        path={templates}
        render={() => (
          <Layout title={title}>
            <TemplatesProvider>
              <TemplatesList title={title} />
            </TemplatesProvider>
          </Layout>
        )}
      />
      <Route
        exact
        path={templateVersions}
        render={() => (
          <Layout title={title} renderappbar={'false'} nopadding={1}>
            <TemplateVersions />
          </Layout>
        )}
      />
      <Route
        exact
        path={templatePreview}
        render={() => (
          <Layout title={title} renderappbar={'false'} nopadding={1}>
            <PlaceholderProvider>
              <TemplatePreview />
            </PlaceholderProvider>
          </Layout>
        )}
      />
      <Route
        exact
        path={templateEditor}
        render={() => (
          <Layout title={title} nopadding={1}>
            <TemplateEditor />
          </Layout>
        )}
      />
      <Route
        exact
        path={templateStarters}
        render={() => (
          <Layout title={title}>
            <TemplateStarters />
          </Layout>
        )}
      />
    </Switch>
  );
};

export default Templates;
